import React, { useState, useEffect } from "react";
import { Card, Row } from "reactstrap";
import { CadastroFolhaPortaModal } from "./components/CadastroFolhaPortaModal";
import { FolhaPortaGrid } from "./components/FolhaPortaGrid";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
  tiposMaterial,
} from "../../../coreUtils";
import { apiGet } from "../../../api";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  IntegerInput,
  PageContainer,
} from "../../../components";

const espessurasFolha = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

const mostrarOptions = [
  { label: "Todos", value: "TOD" },
  { label: "Somente com Negativo", value: "CNG" },
  { label: "Somente sem Negativo", value: "SNG" },
];

export const FolhaPorta = () => {
  const [altura, setAltura] = useState(null);
  const [largura, setLargura] = useState(null);
  const [alturasFolhas, setAlturasFolhas] = useState([]);
  const [largurasFolhas, setLargurasFolhas] = useState([]);
  const [espessura, setEspessura] = useState(null);
  const [cor, setCor] = useState(null);
  const [tipoMaterial, setTipoMaterial] = useState(null);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const buscarDados = async () => {
    const ret = await apiGet("/cadastro/folha_porta/listar", {
      tipo_material: tipoMaterial,
      altura: altura,
      largura: largura,
      espessura: espessura,
      id_cor: cor,
      mostrar: mostrar,
    });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const buscarLargurasAlturas = async () => {
    const retLarguras = await apiGet("/cadastro/folha_porta/listar_larguras/");
    setLargurasFolhas(retLarguras ?? []);

    const retAlturas = await apiGet("/cadastro/folha_porta/listar_alturas/");
    setAlturasFolhas(retAlturas ?? []);
  };

  useEffect(() => {
    buscarLargurasAlturas();
  }, []);

  return (
    <PageContainer title="Cadastro de Folhas de Porta" number="0007">
      <Card body>
        <Row>
          <AsyncComboBox
            label="Cor de Acabamento"
            isConcatField
            concatModelName="cor_acabamento"
            defaultOptions
            value={cor}
            onChange={setCor}
            md={2}
            isClearable
          />
          <ComboBox
            md={2}
            label="Altura"
            options={alturasFolhas}
            value={altura}
            onChange={setAltura}
            isSearchable
            isClearable
          />
          <ComboBox
            md={2}
            label="Largura"
            options={largurasFolhas}
            value={largura}
            onChange={setLargura}
            isSearchable
            isClearable
          />
          <ComboBox
            label="Espessura"
            options={espessurasFolha}
            value={espessura}
            onChange={setEspessura}
            isSearchable={false}
            md={2}
            isClearable
          />
          <ComboBox
            md={2}
            label="Material"
            options={tiposMaterial}
            value={tipoMaterial}
            onChange={setTipoMaterial}
            isClearable
          />
          <ComboBox
            md={2}
            label="Mostrar"
            options={mostrarOptions}
            value={mostrar}
            onChange={setMostrar}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroFolhaPortaModal
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <FolhaPortaGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};

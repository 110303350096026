import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  IntegerInput,
  PageContainer,
} from "../../../components";
import { CadastroVistaModal } from "./components/CadastroVistaModal";
import { VistaGrid } from "./components/VistaGrid";
import { MODAL_ACTIONS, tiposMaterial } from "../../../coreUtils";
import { apiGet } from "../../../api";

const tiposVista = [
  { label: "Fixa", value: "FIXA" },
  { label: "Regulável", value: "REGULAVEL" },
];

export const Vista = () => {
  const [tipoMaterial, setTipoMaterial] = useState(null);
  const [cor, setCor] = useState(null);
  const [tipoVista, setTipoVista] = useState(null);
  const [largura, setLargura] = useState(null);
  const [comprimento, setComprimento] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const buscarDados = async () => {
    const ret = await apiGet("/cadastro/vista/listar/", {
      tipo_material: tipoMaterial,
      id_cor: cor,
      tipo_vista: tipoVista,
      largura: largura,
      comprimento: comprimento,
    });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer title="Cadastro de Vista" number="0010">
      <Card body>
        <Row>
          <ComboBox
            md={2}
            label="Material"
            options={tiposMaterial}
            value={tipoMaterial}
            onChange={setTipoMaterial}
            isClearable
          />
          <AsyncComboBox
            label="Cor de Acabamento"
            isConcatField
            concatModelName="cor_acabamento"
            defaultOptions
            value={cor}
            onChange={setCor}
            md={2}
            isClearable
          />
          <ComboBox
            md={2}
            label="Tipo"
            options={tiposVista}
            value={tipoVista}
            onChange={setTipoVista}
            isClearable
          />
          <IntegerInput
            md={2}
            label="Largura"
            value={largura}
            onChange={setLargura}
            placeholder="Em Milímetros"
          />
          <IntegerInput
            md={2}
            label="Comprimento"
            value={comprimento}
            onChange={setComprimento}
            placeholder="Em Milímetros"
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroVistaModal selected={selected} notifyEvent={notifyEvent} />
        </Row>
      </Card>
      <Card body>
        <VistaGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};

import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoImprimir,
  ComboBox,
  FormButton,
  PageContainer,
  TextInput,
} from "../../../components";
import PrintingService from "../../../services/PrintingService";
import readXlsxFile from "read-excel-file";
import DragAndDropArquivos from "../../../components/DragAndDropArquivos";
import { v4 as uuidv4 } from "uuid";
import { toastr } from "react-redux-toastr";
import { etiquetaPortaItemIndividual } from "./layouts/itemIndividual";
import { imprimirEtiquetasPortaCorrer } from "./layouts/portasCorrer";
import { imprimirEtiquetasPortaAbrir } from "./layouts/portasAbrir";
import { ImpressaoEtiquetasManualGrid } from "./components/ImpressaoEtiquetasManualGrid";
import { etiquetaMarco } from "./layouts/marco";

const tiposPortasOptions = [
  { label: "Abrir", value: "ABRIR" },
  { label: "Correr", value: "CORRER" },
  { label: "Marco de Passagem", value: "MARCO_PASSAGEM" },
  { label: "Outros", value: "OUTROS" },
];

export const ImpressaoEtiquetasManual = () => {
  const [dadosOriginais, setDadosOriginais] = useState([]);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingImpressaoEtiquetas, setLoadingImpressaoEtiquetas] =
    useState(false);
  // Filtros
  const [tipo, setTipo] = useState(null);
  const [nroPedido, setNroPedido] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [clienteFinal, setClienteFinal] = useState(null);

  const carregarArquivo = async (file) => {
    setSelected([]);
    const dadosLidos = [];

    let portasAbrir = await readXlsxFile(file, { sheet: "ABRIR" });
    // Remove as duas primeiras linhas que contém o cabeçalho
    portasAbrir = portasAbrir.slice(2);
    for (const item of portasAbrir) {
      if (item[2] === null) break;
      dadosLidos.push({
        uuid: uuidv4(),
        seq: item[3],
        nro_pedido: item[2],
        tipo: "ABRIR",
        cliente: item[4],
        cliente_final: item[6],
        nome_porta: item[5],
        largura_porta: item[7],
        altura_porta: item[9],
        marco: item[11],
        qtd_vista_regulavel: item[12],
        qtd_vista_fixa: item[13],
        largura_vista: item[14],
        espessura_vista: item[15],
        cor: item[16],
        material: item[17],
        lado: item[18],
        fechadura: item[22],
        referencia_fecha: item[23],
        tipo_fecha: item[24],
      });
    }

    let portasCorrer = await readXlsxFile(file, { sheet: "CORRER" });
    // Remove as duas primeiras linha que contém o cabeçalho
    portasCorrer = portasCorrer.slice(2);
    for (const item of portasCorrer) {
      if (item[2] === null) break;
      dadosLidos.push({
        uuid: uuidv4(),
        seq: item[2],
        nro_pedido: item[0],
        tipo: "CORRER",
        cliente: item[3],
        cliente_final: item[5],
        nome_porta: item[4],
        largura_porta: item[6],
        altura_porta: item[7],
        material: "",
        marco: item[8],
        cor: item[9],
        lado: item[10],
        fechadura: item[11],
        referencia_fecha: "",
        tipo_fecha: "",
        qtd_vista_fixa: "",
        qtd_vista_regulavel: "",
        largura_vista: "",
        espessura_vista: "",
      });
    }

    let marcosPassagem = await readXlsxFile(file, { sheet: "MARCO PASSAGEM" });

    // Remove as duas primeiras linha que contém o cabeçalho
    marcosPassagem = marcosPassagem.slice(2);
    for (const item of marcosPassagem) {
      if (item[2] === null) break;
      debugger;
      dadosLidos.push({
        uuid: uuidv4(),
        seq: item[2],
        nro_pedido: item[0],
        tipo: "MARCO PASSAGEM",
        cliente: item[3],
        cliente_final: "",
        nome_porta: item[4],
        largura_porta: item[6],
        altura_porta: item[7],
        material: "",
        marco: "",
        cor: item[9],
        fechadura: "",
        referencia_fecha: "",
        tipo_fecha: "",
        qtd_vista_fixa: "",
        lado: "",
        qtd_vista_regulavel: "",
        espessura_vista: item[8],
      });
    }

    let itemIndividuais = await readXlsxFile(file, { sheet: "ITENS INDIV." });
    // Remove as duas primeiras linhas que contém o cabeçalho
    itemIndividuais = itemIndividuais.slice(2);
    for (const item of itemIndividuais) {
      if (item[2] === null) break;
      dadosLidos.push({
        uuid: uuidv4(),
        seq: item[0],
        nro_pedido: item[2],
        tipo: item[3],
        cliente: item[4],
        cliente_final: item[6],
        nome_porta: item[5],
        largura_porta: item[7],
        altura_porta: item[9],
        marco: item[11],
        qtd_vista_fixa: item[12],
        qtd_vista_regulavel: item[13],
        cor: item[14],
        material: item[15],
        lado: item[16],
        usinagem: item[18],
        fechadura: item[21],
        referencia_fecha: item[22],
        tipo_fecha: item[23],
        largura_vista: "",
        espessura_vista: "",
      });
    }
    setDados(dadosLidos);
    setDadosOriginais(dadosLidos);
  };

  const carregarDados = async (file) => {
    await carregarArquivo(file);
  };

  const imprimirEtiquetas = async () => {
    setLoadingImpressaoEtiquetas(true);
    try {
      const portasAbrir = selected.filter((e) => e.tipo === "ABRIR");
      if (portasAbrir.length > 0) {
        await imprimirEtiquetasPortaAbrir(portasAbrir);
      }

      const portasCorrer = selected.filter((e) => e.tipo === "CORRER");
      if (portasCorrer.length > 0) {
        await imprimirEtiquetasPortaCorrer(portasCorrer);
      }

      const etiquetaPassagem = await etiquetaMarco(
        selected.filter((e) => ["MARCO PASSAGEM"].includes(e.tipo)),
        null,
        "MARCO DE PASSAGEM",
        "ZPL",
        30
      );
      debugger;
      if (etiquetaPassagem.length > 0) {
        await PrintingService.printLabel(etiquetaPassagem, "ZD220 - LARANJA");
        await PrintingService.printLabel(etiquetaPassagem, "ZD220 - VERDE");
      }

      const etiquetaFolhaUsinada = await etiquetaPortaItemIndividual(
        selected.filter(
          (e) =>
            ![("CORRER", "ABRIR", "MARCO PASSAGEM")].includes(e.tipo) &&
            e.usinagem === "SIM"
        )
      );
      if (etiquetaFolhaUsinada.length > 0) {
        await PrintingService.printLabel(etiquetaFolhaUsinada, "ZD220 - AZUL");
      }

      const semUsinagem = await etiquetaPortaItemIndividual(
        selected.filter(
          (e) =>
            ![("CORRER", "ABRIR", "MARCO PASSAGEM")].includes(e.tipo) &&
            e.usinagem !== "SIM"
        )
      );
      if (semUsinagem.length > 0) {
        await PrintingService.printLabel(semUsinagem, "ZD220 - AZUL");
      }
    } catch (e) {
      toastr.error("Erro ao Imprimir Etiquetas", e);
    } finally {
      setLoadingImpressaoEtiquetas(false);
    }
  };

  useEffect(() => {
    let dadosFiltrados = dadosOriginais;
    if (tipo) {
      dadosFiltrados = dadosFiltrados.filter((e) => {
        if (tipo == "OUTROS") {
          return ![("CORRER", "MARCO PASSAGEM", "ABRIR")].includes(e.tipo);
        }

        return e.tipo == tipo;
      });
    }
    if (nroPedido) {
      dadosFiltrados = dadosFiltrados.filter(
        (e) => String(e.nro_pedido) == nroPedido
      );
    }
    if (cliente) {
      dadosFiltrados = dadosFiltrados.filter((e) =>
        String(e.cliente).includes(cliente)
      );
    }
    if (clienteFinal) {
      dadosFiltrados = dadosFiltrados.filter((e) =>
        String(e.cliente_final).includes(clienteFinal)
      );
    }

    setSelected([]);
    setDados(dadosFiltrados);
  }, [tipo, nroPedido, cliente, clienteFinal]);

  const limparDados = () => {
    setTipo(null);
    setNroPedido(null);
    setCliente(null);
    setClienteFinal(null);
    setDados([]);
    setDadosOriginais([]);
  };

  return (
    <PageContainer title="Impressão Manual de Etiquetas" number="0001">
      {dadosOriginais.length === 0 ? (
        <Card body>
          <DragAndDropArquivos md={12} onDrop={carregarDados} />
        </Card>
      ) : (
        <Card body>
          <Row>
            <BotaoImprimir
              onClick={imprimirEtiquetas}
              disabled={(selected?.length ?? 0) === 0}
              text="Imprimir Etiquetas Selecionadas"
              loading={loadingImpressaoEtiquetas}
              padded={false}
            />
            <FormButton
              padded={false}
              onClick={limparDados}
              color="warning"
              divClassName="ml-auto"
            >
              Importar Outro Arquivo
            </FormButton>
          </Row>
          <Row className="mb-2">
            <ComboBox
              options={tiposPortasOptions}
              label="Tipo"
              md={2}
              isSearchable={false}
              isClearable
              onChange={(v) => setTipo(v)}
            />
            <TextInput label="Nº Pedido" onChange={setNroPedido} />
            <TextInput label="Cliente" onChange={setCliente} />
            <TextInput label="Cliente Final" onChange={setClienteFinal} />
          </Row>
          <ImpressaoEtiquetasManualGrid
            data={dados}
            setSelected={(_, checked, row) => {
              if (checked) {
                setSelected([...selected, row]);
              } else {
                setSelected([...selected.filter((e) => e.uuid !== row.uuid)]);
              }
            }}
            setSelectedAll={(checked) => {
              if (!checked) {
                setSelected([]);
              } else {
                setSelected(dados);
              }
            }}
          />
        </Card>
      )}
    </PageContainer>
  );
};

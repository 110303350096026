import React from "react";
import { useState } from "react";
import {
  ModalCadastro,
  NumberInput,
  IntegerInput,
  AsyncComboBox,
  ComboBox,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { Row } from "reactstrap";

const alturas = [
  { label: "2180cm", value: 2180 },
  { label: "2750cm", value: 2750 },
];

export const CadastroBatenteModal = ({ selected, notifyEvent }) => {
  const [cor, setCor] = useState(null);
  const [altura, setAltura] = useState(alturas[0].value);
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setCor(null);
    setAltura(alturas[0].value);
    setVlrCusto(0);
    setVlrPreco(0);
    setIdIntegracao(0);
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setCor(data.id_cor);
    setAltura(parseFloat(data.altura));
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setIdIntegracao(data.id_integracao);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      id_cor: cor,
      altura: altura,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      id_integracao: idIntegracao,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_batente: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Batente"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/batente"
      number="0003_1"
      deleteModalNumber="0003_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          label="Cor de Acabamento"
          isConcatField
          concatModelName="cor_acabamento"
          defaultOptions
          value={cor}
          onChange={setCor}
          md={8}
        />
        <ComboBox
          md={4}
          label="Altura"
          options={alturas}
          value={altura}
          onChange={setAltura}
          isSearchable={false}
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Custo"
          value={vlrCusto}
          onChange={setVlrCusto}
        />
        <NumberInput
          md={4}
          label="Preco"
          value={vlrPreco}
          onChange={setVlrPreco}
        />
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={12}
        />
      </Row>
    </ModalCadastro>
  );
};

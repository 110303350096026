import React from "react";
import { useState } from "react";
import {
  ComboBox,
  ModalCadastro,
  NumberInput,
  TextInput,
} from "../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS, ufs } from "../../../../coreUtils";

export const CadastroCidadeModal = ({ selected, notifyEvent }) => {
  const [nome, setNome] = useState("");
  const [uf, setUf] = useState("");
  const [vlrFrete, setVlrFrete] = useState(0);

  const limparDados = () => {
    setNome("");
    setUf("");
    setVlrFrete(0);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setUf(data.uf);
    setVlrFrete(parseFloat(data.vlr_frete));
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      uf: uf,
      vlr_frete: vlrFrete,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Cidade"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/cidade"
      number="0002_1"
      deleteModalNumber="0002_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={9}
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={80}
        />
        <ComboBox md={3} options={ufs} label="UF" value={uf} onChange={setUf} />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Vlr. Frete"
          value={vlrFrete}
          onChange={setVlrFrete}
        />
      </Row>
    </ModalCadastro>
  );
};

import React, { useEffect } from "react";
import { useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  IntegerInput,
  ModalCadastro,
  NumberInput,
  RadioGroup,
} from "../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS, tiposMaterial } from "../../../../coreUtils";
import FormLabel from "../../../../components/FormLabel";

const espessurasFolha = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

const comprimentoMarcoMDF = [
  { label: "2180mm", value: 2180 },
  { label: "2750mm", value: 2750 },
];

const comprimentoMarcoPVC = [
  { label: "2150mm", value: 2150 },
  { label: "2750mm", value: 2750 },
];

export const CadastroFolhaPortaModal = ({ selected, notifyEvent }) => {
  const [ativo, setAtivo] = useState(true);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [largura, setLargura] = useState(null);
  const [altura, setAltura] = useState(null);
  const [espessura, setEspessura] = useState(espessurasFolha[0].value);
  const [comprimentoPadMarco, setComprimentoPadMarco] = useState(
    comprimentoMarcoPVC[0].value
  );
  const [cor, setCor] = useState(null);
  const [qtdDobradicas, setQtdDobradicas] = useState(0);
  const [vlrCusto, setVlrCusto] = useState(0);
  const [vlrPreco, setVlrPreco] = useState(0);
  const [peso, setPeso] = useState(0);
  const [idIntegracao, setIdIntegracao] = useState(0);
  const [temNegativo, setTemNegativo] = useState(false);
  const [idGrupoDesc, setIdGrupoDesc] = useState(null);

  const limparDados = () => {
    setAtivo(true);
    setTipoMaterial(tiposMaterial[0].value);
    setLargura(0);
    setAltura(0);
    setEspessura(espessurasFolha[0].value);
    setComprimentoPadMarco(comprimentoMarcoPVC[0].value);
    setCor(null);
    setQtdDobradicas(0);
    setVlrCusto(0);
    setVlrPreco(0);
    setPeso(0);
    setIdIntegracao(0);
    setTemNegativo(false);
    setIdGrupoDesc(null);
  };

  const fetchData = (data) => {
    setTipoMaterial(data.tipo_material);
    setQtdDobradicas(data.qtd_dobradicas);
    setLargura(parseFloat(data.largura));
    setAltura(parseFloat(data.altura));
    setEspessura(parseFloat(data.espessura).toFixed(0));
    setComprimentoPadMarco(parseInt(data.comprimento_marco));
    setCor(data.id_cor);
    setPeso(parseFloat(data.peso));
    setVlrCusto(parseFloat(data.vlr_custo));
    setVlrPreco(parseFloat(data.vlr_preco));
    setAtivo(data.ativo);
    setIdIntegracao(data.id_integracao);
    setTemNegativo(data.tem_negativo);
    setIdGrupoDesc(data.id_grupo_desc);
  };

  const submitPayload = (action) => {
    const payload = {
      tipo_material: tipoMaterial,
      largura: largura,
      altura: altura,
      espessura: parseFloat(espessura),
      qtd_dobradicas: qtdDobradicas,
      comprimento_pad_marco: comprimentoPadMarco,
      id_cor: cor,
      vlr_custo: vlrCusto,
      vlr_preco: vlrPreco,
      peso: peso,
      ativo: ativo,
      id_integracao: idIntegracao,
      tem_negativo: temNegativo,
      id_grupo_desc: idGrupoDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  useEffect(() => {
    if (tipoMaterial == "PVC") {
      setComprimentoPadMarco(comprimentoMarcoPVC[0].value);
    } else {
      setComprimentoPadMarco(comprimentoMarcoMDF[0].value);
    }
  }, [tipoMaterial]);

  return (
    <ModalCadastro
      title="Folha de Porta"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase="/cadastro/folha_porta"
      number="0007_1"
      deleteModalNumber="0007_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <RadioGroup
          label="Material"
          options={tiposMaterial}
          value={tipoMaterial}
          onChange={setTipoMaterial}
        />
        <FormCheckbox
          divClassName="ml-auto"
          label="Folha com Negativo"
          checked={temNegativo}
          onChange={() => setTemNegativo(!temNegativo)}
        />
      </Row>
      <Row>
        <IntegerInput
          md={3}
          label="Altura"
          value={altura}
          onChange={setAltura}
          placeholder="Em Milímetros"
        />
        <FormLabel md={1}>{`${altura / 1000}m`}</FormLabel>
        <IntegerInput
          md={3}
          label="Largura"
          value={largura}
          onChange={setLargura}
          placeholder="Em Milímetros"
        />
        <FormLabel md={1}>{`${largura / 1000}m`}</FormLabel>
        <ComboBox
          label="Espessura"
          options={espessurasFolha}
          value={espessura}
          onChange={setEspessura}
          isSearchable={false}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Cor de Acabamento"
          isConcatField
          concatModelName="cor_acabamento"
          defaultOptions
          value={cor}
          onChange={setCor}
          md={6}
        />
        <ComboBox
          label="Comprimento Padrão do Jogo de Marco"
          options={
            tipoMaterial == "PVC" ? comprimentoMarcoPVC : comprimentoMarcoMDF
          }
          value={comprimentoPadMarco}
          onChange={setComprimentoPadMarco}
          md={6}
        />
      </Row>
      <Row>
        <IntegerInput
          label="Qtd. Dobradiças"
          value={qtdDobradicas}
          onChange={setQtdDobradicas}
        />
        <NumberInput label="Peso (Kg)" value={peso} onChange={setPeso} />
        <NumberInput label="Custo" value={vlrCusto} onChange={setVlrCusto} />
      </Row>
      <Row>
        <NumberInput
          label="Preço"
          value={vlrPreco}
          onChange={setVlrPreco}
          md={4}
        />
        <IntegerInput
          label="Código Integração"
          md={4}
          value={idIntegracao}
          onChange={setIdIntegracao}
        />
        <AsyncComboBox
          label="Grupo de Desconto"
          isConcatField
          concatModelName="grupo_desc"
          defaultOptions
          value={idGrupoDesc}
          onChange={setIdGrupoDesc}
          md={4}
        />
      </Row>
    </ModalCadastro>
  );
};

import React, { useState, useEffect } from "react";
import { Card, Row } from "reactstrap";
import { CadastroMarcoModal } from "./components/CadastroMarcoModal";
import { MarcoGrid } from "./components/MarcoGrid";
import { MODAL_ACTIONS, tiposMaterial } from "../../../coreUtils";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  IntegerInput,
  PageContainer,
} from "../../../components";
import { apiGet } from "../../../api";

const largurasMarcos = [
  { label: "70mm", value: 70 },
  { label: "90mm", value: 90 },
  { label: "115mm", value: 115 },
  { label: "140mm", value: 140 },
  { label: "160mm", value: 160 },
  { label: "190mm", value: 190 },
  { label: "230mm", value: 230 },
];

const rebaixosMarco = [
  { label: "36mm", value: 36 },
  { label: "41mm", value: 41 },
];

const tiposMarco = [
  { label: "Normal com Rebaixo", value: "NORM" },
  { label: "Passagem", value: "PASG" },
];

export const Marco = () => {
  const [cor, setCor] = useState(null);
  const [comprimento, setComprimento] = useState(null);
  const [comprimentos, setComprimentos] = useState([]);
  const [largura, setLargura] = useState(null);
  const [rebaixo, setRebaixo] = useState(null);
  const [tipoMaterial, setTipoMaterial] = useState(null);
  const [tipoMarco, setTipoMarco] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const buscarDados = async (p) => {
    const ret = await apiGet("/cadastro/marco/listar", {
      id_cor: cor,
      comprimento: comprimento,
      largura: largura,
      rebaixo: rebaixo,
      tipo_material: tipoMaterial,
      tipo_marco: tipoMarco,
    });
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const buscarComprimentos = async () => {
    const ret = await apiGet("/cadastro/marco/listar_comprimentos/");
    setComprimentos(ret ?? []);
  };

  useEffect(() => {
    buscarComprimentos();
  }, []);

  return (
    <PageContainer title="Cadastro de Jogos de Marcos" number="0006">
      <Card body>
        <Row>
          <AsyncComboBox
            label="Cor de Acabamento"
            isConcatField
            concatModelName="cor_acabamento"
            defaultOptions
            value={cor}
            onChange={setCor}
            md={2}
            isClearable
          />
          <ComboBox
            md={2}
            label="Comprimento"
            options={comprimentos}
            value={comprimento}
            onChange={setComprimento}
            isClearable
            isSearchable
          />
          <ComboBox
            label="Largura"
            options={largurasMarcos}
            value={largura}
            onChange={setLargura}
            isSearchable={false}
            md={2}
            isClearable
          />
          <ComboBox
            md={2}
            label="Material"
            options={tiposMaterial}
            value={tipoMaterial}
            onChange={setTipoMaterial}
            isClearable
          />
          <ComboBox
            md={2}
            label="Tipo de Marco"
            options={tiposMarco}
            value={tipoMarco}
            onChange={setTipoMarco}
            isClearable
          />
          <ComboBox
            md={2}
            label="Rebaixo"
            options={rebaixosMarco}
            value={rebaixo}
            onChange={setRebaixo}
            isClearable
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroMarcoModal selected={selected} notifyEvent={notifyEvent} />
        </Row>
      </Card>
      <Card body>
        <MarcoGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};

import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoImprimir,
  BotaoPesquisar,
  PageContainer,
  TextInput,
} from "../../../components";
import { ImpressaoEtiquetasGrid } from "./components/ImpressaoEtiquetasGrid";
import PrintingService from "../../../services/PrintingService";
import { v4 as uuidv4 } from "uuid";
import { toastr } from "react-redux-toastr";
import { etiquetaPortaItemIndividual } from "./layouts/itemIndividual";
import { imprimirEtiquetasPortaCorrer } from "./layouts/portasCorrer";
import { imprimirEtiquetasPortaAbrir } from "./layouts/portasAbrir";
import { apiGet } from "../../../api";
import { etiquetaMarco } from "./layouts/marco";

export const ImpressaoEtiquetasPedidos = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingPesquisa, setLoadingPesquisa] = useState(false);
  const [loadingImpressaoEtiquetas, setLoadingImpressaoEtiquetas] =
    useState(false);
  // Filtros
  const [nroPedido, setNroPedido] = useState(null);

  // const carregarArquivo = async (file) => {
  //   setSelected([]);

  //   let portasAbrir = await readXlsxFile(file, { sheet: "ABRIR" });
  //   // Remove as duas primeiras linhas que contém o cabeçalho
  //   portasAbrir = portasAbrir.slice(2);
  //   for (const item of portasAbrir) {
  //     if (item[2] === null) break;
  //     dadosLidos.push({
  //       uuid: uuidv4(),
  //       seq: item[3],
  //       nro_pedido: item[2],
  //       tipo: "ABRIR",
  //       cliente: item[4],
  //       cliente_final: item[6],
  //       nome_porta: item[5],
  //       largura_porta: item[7],
  //       altura_porta: item[9],
  //       marco: item[11],
  //       qtd_vista_regulavel: item[12],
  //       qtd_vista_fixa: item[13],
  //       largura_vista: item[14],
  //       espessura_vista: item[15],
  //       cor: item[16],
  //       material: item[17],
  //       lado: item[18],
  //       fechadura: item[22],
  //       referencia_fecha: item[23],
  //       tipo_fecha: item[24],
  //     });
  //   }

  //   let portasCorrer = await readXlsxFile(file, { sheet: "CORRER" });
  //   // Remove as duas primeiras linha que contém o cabeçalho
  //   portasCorrer = portasCorrer.slice(2);
  //   for (const item of portasCorrer) {
  //     if (item[2] === null) break;
  //     dadosLidos.push({
  //       uuid: uuidv4(),
  //       seq: item[2],
  //       nro_pedido: item[0],
  //       tipo: "CORRER",
  //       cliente: item[3],
  //       cliente_final: item[5],
  //       nome_porta: item[4],
  //       largura_porta: item[6],
  //       altura_porta: item[7],
  //       material: "",
  //       marco: item[8],
  //       cor: item[9],
  //       lado: item[10],
  //       fechadura: item[11],
  //       referencia_fecha: "",
  //       tipo_fecha: "",
  //       qtd_vista_fixa: "",
  //       qtd_vista_regulavel: "",
  //       largura_vista: "",
  //       espessura_vista: "",
  //     });
  //   }

  //   let marcosPassagem = await readXlsxFile(file, { sheet: "MARCO PASSAGEM" });

  //   // Remove as duas primeiras linha que contém o cabeçalho
  //   marcosPassagem = marcosPassagem.slice(2);
  //   for (const item of marcosPassagem) {
  //     if (item[2] === null) break;
  //     dadosLidos.push({
  //       uuid: uuidv4(),
  //       seq: item[2],
  //       nro_pedido: item[0],
  //       tipo: "MARCO PASSAGEM",
  //       cliente: item[3],
  //       cliente_final: "",
  //       nome_porta: item[4],
  //       largura_porta: item[5],
  //       altura_porta: item[6],
  //       material: "",
  //       marco: item[7],
  //       cor: item[8],
  //       fechadura: "",
  //       referencia_fecha: "",
  //       tipo_fecha: "",
  //       qtd_vista_fixa: "",
  //       qtd_vista_regulavel: "",
  //     });
  //   }

  //   let itemIndividuais = await readXlsxFile(file, { sheet: "ITENS INDIV." });
  //   // Remove as duas primeiras linhas que contém o cabeçalho
  //   itemIndividuais = itemIndividuais.slice(2);
  //   for (const item of itemIndividuais) {
  //     if (item[2] === null) break;
  //     dadosLidos.push({
  //       uuid: uuidv4(),
  //       seq: item[0],
  //       nro_pedido: item[2],
  //       tipo: item[3],
  //       cliente: item[4],
  //       cliente_final: item[6],
  //       nome_porta: item[5],
  //       largura_porta: item[7],
  //       altura_porta: item[9],
  //       marco: item[11],
  //       qtd_vista_fixa: item[12],
  //       qtd_vista_regulavel: item[13],
  //       cor: item[14],
  //       material: item[15],
  //       lado: item[16],
  //       usinagem: item[18],
  //       fechadura: item[21],
  //       referencia_fecha: item[22],
  //       tipo_fecha: item[23],
  //       largura_vista: "",
  //       espessura_vista: "",
  //     });
  //   }
  //   setDados(dadosLidos);
  //   setDadosOriginais(dadosLidos);
  // };

  const carregarDados = async () => {
    setLoadingPesquisa(true);
    setDados([]);
    try {
      const ret = await apiGet("pedido/adm/listar_impressao_etiqueta/", {
        num_pedido: nroPedido,
      });
      if (ret) {
        setDados(ret);
      }
    } finally {
      setLoadingPesquisa(false);
    }
  };

  const imprimirEtiquetas = async () => {
    setLoadingImpressaoEtiquetas(true);
    try {
      debugger;
      if (["KABR", "KCOR"].includes(selected.tipo_pedido)) {
        const ret = await apiGet(`/pedido/buscar_dados/${selected.id}/`);

        if (ret) {
          const itensPed = ret.itens;

          const portasAbrir = itensPed.filter(
            (e) => e.modo_abertura === "Abrir"
          );
          if (portasAbrir.length > 0) {
            await imprimirEtiquetasPortaAbrir(
              portasAbrir.map((p) => {
                return {
                  uuid: uuidv4(),
                  seq: p.item,
                  nro_pedido: selected.id,
                  tipo: "ABRIR",
                  cliente: ret.nome_usuario, // TODO: Buscar dados do Cliente
                  cliente_final: "", // TODO: Buscar dados do cliente
                  nome_porta: p.descricao,
                  largura_porta: p.fol_largura_pronta,
                  altura_porta: p.fol_altura_pronta,
                  marco: p.compr_marco,
                  qtd_vista_regulavel: p.qtd_vista_regulavel,
                  qtd_vista_fixa: p.qtd_vista_fixa,
                  largura_vista: p.largura_vista,
                  espessura_vista: p.compr_vista,
                  cor: p.nome_cor_acabamento,
                  material: p.tipo_material,
                  lado: p.sigla_lado_abert,
                  fechadura: p.nome_fechadura,
                  referencia_fecha: p.ref_fechadura,
                  tipo_fecha: "",
                };
              })
            );
          }

          const portasCorrer = itensPed.filter(
            (e) => e.modo_abertura === "De Correr"
          );
          if (portasCorrer.length > 0) {
            await imprimirEtiquetasPortaCorrer(
              portasCorrer.map((p) => {
                debugger;
                return {
                  uuid: uuidv4(),
                  seq: p.item,
                  nro_pedido: selected.id,
                  tipo: "CORRER",
                  cliente: ret.nome_usuario, // TODO: Buscar dados do Cliente
                  cliente_final: "", // TODO: Buscar dados do cliente
                  nome_porta: p.descricao,
                  largura_porta: p.fol_largura_pronta,
                  altura_porta: p.fol_altura_pronta,
                  marco: p.compr_marco,
                  qtd_vista_regulavel: p.qtd_vista_regulavel,
                  qtd_vista_fixa: p.qtd_vista_fixa,
                  largura_vista: p.largura_vista,
                  espessura_vista: p.compr_vista,
                  cor: p.nome_cor_acabamento,
                  material: p.tipo_material,
                  lado: p.sigla_lado_abert,
                  fechadura: p.nome_fechadura,
                  referencia_fecha: p.ref_fechadura,
                  tipo_fecha: "",
                };
              })
            );
          }

          const etiquetaPassagem = await etiquetaMarco(
            itensPed.filter((e) => ["MARCO PASSAGEM"].includes(e.tipo))
          );
          if (etiquetaPassagem.length > 0) {
            await PrintingService.printLabel(
              etiquetaPassagem,
              "ZD220 - LARANJA"
            );
            await PrintingService.printLabel(etiquetaPassagem, "ZD220 - VERDE");
          }
        }
      } else {
        const ret = await apiGet(`/pedido/componentes/listar/${selected.id}/`);

        if (ret) {
          const itensPed = ret.itens;

          const etiquetaPassagem = await etiquetaMarco(
            itensPed.filter((e) => ["MARC"].includes(e.tipo_componente))
          );
          if (etiquetaPassagem.length > 0) {
            await PrintingService.printLabel(
              etiquetaPassagem,
              "ZD220 - LARANJA"
            );
            await PrintingService.printLabel(etiquetaPassagem, "ZD220 - VERDE");
          }
          // const etiquetaFolhaUsinada = await etiquetaPortaItemIndividual(
          //   itensPed.filter(
          //     (e) =>
          //       ![("CORRER", "MARCO PASSAGEM", "ABRIR")].includes(e.tipo) &&
          //       e.usinagem === "SIM"
          //   )
          // );
          // if (etiquetaFolhaUsinada.length > 0) {
          //   await PrintingService.printLabel(
          //     etiquetaFolhaUsinada,
          //     "Argox OS-214 plus series PPLA"
          //   );
          // }

          const folha = await etiquetaPortaItemIndividual(
            itensPed.filter((e) => ["FOLH"].includes(e.tipo_componente))
          );
          if (folha.length > 0) {
            await PrintingService.printLabel(folha, "ZD220 - AZUL");
          }
        }
      }
    } catch (e) {
      debugger;
      toastr.error("Erro ao Imprimir Etiquetas", e);
    } finally {
      setLoadingImpressaoEtiquetas(false);
    }
  };

  return (
    <PageContainer title="Impressão de Etiquetas de Pedidos" number="0001">
      <Card body>
        <Row className="mb-2">
          <BotaoPesquisar
            onClick={() => carregarDados()}
            loading={loadingPesquisa}
          />
          <BotaoImprimir
            onClick={imprimirEtiquetas}
            disabled={selected === null}
            text="Imprimir Etiquetas Selecionadas"
            loading={loadingImpressaoEtiquetas}
          />
          <TextInput label="Nº Pedido" onChange={setNroPedido} md={2} />
        </Row>
        <ImpressaoEtiquetasGrid
          data={dados}
          setSelected={(_, checked, row) => {
            if (checked) {
              setSelected(row);
            } else {
              setSelected(null);
            }
          }}
        />
      </Card>
    </PageContainer>
  );
};
